















































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Mutation} from 'vuex-class'
import ItemLaborView from './ItemLaborView.vue';
import Confirmacion from '@/components/Confirmacion.vue'
import { LaborModel } from '@/entidades/Plantacion/Partes/LaborModel';
import { ParteDetalleModel, ParteModel } from '@/entidades/Plantacion/Partes/ParteModel';
import ResponseGenerico from '@/entidades/ResponseGenerico';
const nameMaestro = "maestroForestal";
const nameForestal = "laboresForestal";
@Component({
    components:{
        ItemLaborView,
        Confirmacion,
        'cierreLabor' : () => import('@/components/Forestal/Parte/CierreLabor.vue')
    }
})

export default class extends Vue
{   
    agregando=false;
    editando=false;
    nombreComponente:string="";
    modalHora:boolean=false;
    componenteHora:string ="";
    tipoProceso:number =1;

    @Prop({type:Object ,required:true})parte!:ParteModel;
    @Mutation('eliminarDetalle', {namespace:nameForestal} ) eliminarDetalle!:Function;
    @Mutation('actualizarParte', {namespace:nameForestal} ) modificarCantidadParte!:Function;
    @Mutation('setLaborCantidadAumentar', {namespace:nameMaestro} ) incrementarLaborCantidad!:Function;
    parteModelDetalleEdit:ParteDetalleModel = new ParteDetalleModel();
    laboresTmp:LaborModel[]=new Array<LaborModel>();
    headers:any[]= 
    [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'fecha'
        },
        {
          text: 'Fecha Cierre',
          align: 'start',
          sortable: false,
          value: 'fechaCierre'
        },
        {
          text: 'Lote',
          align: 'start',
          sortable: false,
          value: 'loteName'
        },
        {
          text: 'Cuadrilla',
          align: 'start',
          sortable: false,
          value:'cuadrillaName'
        },
        {
          text: 'Labor',
          align: 'start',
          sortable: false,
          value:'laborName'
        },
        {
          text: 'Cantidad',
          align: 'start',
          sortable: false,
          value:'cantidad'
        },
        { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },     
    ];
    get laboresFiltradas():LaborModel[]{
        return this.laboresTmp.filter(e=>e.haciendaId==this.parte.haciendaId)
    }
    eliminarDetalleItem(item:ParteDetalleModel)
    {
        this.eliminarDetalle(item);
        this.incrementarLaborCantidad(item);
        this.modificarCantidadParte(item);
    }

    AbrirAgregarLabor()
    {
        //eliminarDetalle
        this.agregando=true;
        this.editando=false;
        this.nombreComponente='ItemLaborView'
    }
    editarLabor(item:ParteDetalleModel)
    {
        this.parteModelDetalleEdit = item;
        this.agregando = true;
        this.nombreComponente = "cierreLabor";

    }
    limpiarCierre()
    {
        this.agregando = false;
        this.nombreComponente = "";
    }
    cerrarHora()
    {
        this.modalHora = false;
        this.componenteHora = "";
    }
    cancelarModal(){
        this.agregando=false;
        this.nombreComponente=''
    }
}
