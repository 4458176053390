














































































































import {Vue, Component} from 'vue-property-decorator'
import Confirmacion from '@/components/Confirmacion.vue'
import {State, Action, Mutation} from 'vuex-class'
import ParteView from '@/components/Forestal/Parte/ParteView.vue'
import { ItemParteModel } from '@/entidades/Plantacion/Partes/ItemParteModel';
import LaborCrud from '@/components/Forestal/Parte/LaborCrud.vue'
import TablaReutilizable from '@/components/TablaReutilizable.vue'
import { TableBtnModel } from '@/entidades/TableBtnModel';
import {GuardarParte,ParteModel} from '@/entidades/Plantacion/Partes/ParteModel';
import Loading from '@/entidades/Sistema/Loading'
import Alerta from '@/entidades/Sistema/Alerta'
import { CuadrillaHorario } from '@/entidades/Plantacion/CuadrillaHorario';
import moment from 'moment';
const nameForestal = "laboresForestal";
const nameMaestro = "maestroForestal";
@Component({
    components:{
        Confirmacion,
        ParteView,
        LaborCrud,
        TablaReutilizable
    }
})

export default class extends Vue
{
    //#region STATE
    @State('horarioEmpleados', {namespace:nameForestal} ) horarioEmpleados!: Array<CuadrillaHorario>;
    //#endregion
    itemTmp:ParteModel=new ParteModel();
    @Mutation('removerParteLocal', {namespace:nameForestal} ) removerParteLocal!:Function;
    @Mutation('setLaborCantidadAumentar', {namespace:nameMaestro} ) incrementarLaborCantidad!:Function;
    @State('partesLocales', {namespace:nameForestal} ) partesLocales!: ParteModel[];
    @Action('removeParteLocalAction', {namespace:nameForestal}) removeParteLocalAction!:Function;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    LaborCrudName='LaborCrud';
    RegistroHorasName='RegistroHorasView'
    ParteViewName='ParteView';
    agregando: boolean= false;
    agregandoLabores: boolean= false;
    eliminando: boolean= false;
    enviando: boolean= false;
    editando: boolean= false;
    modalValidacion:boolean=false;
    parteValidado:boolean=false;
    startValidacion:boolean=false;
    endValidacion:boolean=false;
    size:number=0;
    mensajeValidacion:string="";
    headers:any[]= 
    [
        {
          text: 'Fecha',
          align: 'start',
          value: 'fecha'
        },
        {
          text: 'Hacienda',
          align: 'start',
          value: 'haciendaName'
        },
        {
          text: 'Orden Interna',
          align: 'start',
          value: 'ordenInterna'
        },
        {
          text: 'TipoCuadrilla',
          align: 'start',
          value:'tipoCuadrillaName'
        },
        {
          text: 'Hectareas',
          align: 'start',
          value:'cantidadTotal'
        },
        { text: 'Acciones', align: 'center', value: 'acciones', sortable: false },        
    ];
    botones:TableBtnModel[]=[
        {
            icon:'fa-tasks',
            label:'',
            action:'AbrirAgregarLabores',
            tooltip:'Agregar Labor',
            necesitaIndex:false
        },
        {
            icon:'fa-share',
            label:'',
            action:'AbrirEnviar',
            tooltip:'Guardar en el servidor',
            necesitaIndex:false
        },
        {
            icon:'fa-trash',
            label:'',
            action:'AbrirEliminar',
            tooltip:'Eliminar',
            necesitaIndex:false
        }
    ]
    
    AbrirAgregarParte()
    {
        this.ParteViewName='ParteView';
        this.agregando=true;
    }


    cancelarAgregar(){
        this.agregando=false;
        this.ParteViewName='';
    }


    AbrirAgregarLabores(item: ParteModel)
    {
        this.LaborCrudName='LaborCrud';
        this.agregandoLabores=true;
        this.itemTmp=item;
    }
    cancelarAgregarLabores(){
        this.agregandoLabores=false;
        this.LaborCrudName='';
    }
    async AbrirEnviar(item:ParteModel)
    {
        this.itemTmp = item;
        this.modalValidacion = true;
    }
    enviarCancel()
    {
        this.enviando=false;
        
    }
    AbrirEliminar(item: ParteModel)
    {
        item.items.forEach(t=>{
            this.incrementarLaborCantidad(t);
        });

       this.removerParteLocal(item.parteId);
    }
    deleteCancel(){
        this.eliminando=false;
    }
    confirmarEliminar(){
        this.removeParteLocalAction(this.itemTmp.parteId);
        this.deleteCancel();
    }

    validarInformacion()
    {
        let parte =this.itemTmp;
        this.startValidacion = true;
        this.mensajeValidacion = "Estamos Validacion su informacion por favor Espere";
       
       try
       {
           if(parte.tipoCuadrilla == 2)
           {
               this.mensajeValidacion = "Validacion correcta";
           }
           else
           {
               this.mensajeValidacion = "Estamos validando , terminaremos dentro de poco tiempo...";
                if(parte.items.length == 0)
                {
                    this.mensajeValidacion = "Validacion con error... El parte no tiene labores registradas";
                    return;
                }
                   
           }
       }
       catch(error)
       {
           this.endValidacion = true;
           //@ts-ignore
           this.mensajeValidacion = error;
       }
       finally
       {
            this.endValidacion = true;

       }

    }
    async guardarDb(item:ParteModel)
    {
        try
        {   await this.changeLoading(new Loading(true,"Validando informacion, antes de enviar"));
            //validamos que la informacion se encuentra registrada

            if(item.items.length == 0)
            {
                     this.changeAlerta(new Alerta("Agregar al menos una labor, para poder enviar",true,"danger",2000));
                    return;
            }

            // if(this.horarioEmpleados.length == 0)
            // {
            //     this.changeAlerta(new Alerta("No tiene asistencia registrada",true,"danger",5000));
            //     this.$router.push({name: 'AsistenciaCuadrilla'})
            //     return;
            // }
            
            //Validamos si existen horas si asignar
          
            let localSto= localStorage.getItem('parteImagen');
            console.log(localSto);
              //@ts-ignore
            var data:[] = JSON.parse(localStorage.getItem('parteImagen'));
            console.log(data);
            item.items.forEach(t=>{
                //@ts-ignore
                var imagenLocal = data.find(c=>c.parteId === t.parteId && c.detalleId === t.detalleId);
                //@ts-ignore
                t.imagen = imagenLocal.imagen;

            });



            if(item.tipoCuadrilla ==1)
            {
                item.items.forEach(t=>{

                    var hora = this.horarioEmpleados.filter(c=> Date.parse(c.fecha) >= Date.parse(t.fecha)
                    && Date.parse(c.fecha) <= Date.parse(t.fechaCierre) && c.registrado == false
                    )
              
                    if(hora.length >0)
                    {
                        this.changeAlerta(new Alerta(`No se puede registrar la  labor, tiene asistencia que no han sido registrada en el servidor ${t.laborName}`,true,"danger",5000));
                        return;
                    }

                });
            }

            console.log('vamos a proceder a registrar');

            let rsp = await GuardarParte(item);
            console.log(rsp);
            if(rsp.isSuccess == true)
            {
                //@ts-ignore
                let imagenOtras = data.filter(c=>c.parteId === item.parteId);
                localStorage.removeItem('parteImagen');
                localStorage.setItem('parteImagen',JSON.stringify(imagenOtras));
                this.removerParteLocal(item.parteId);
                await this.changeAlerta(new Alerta(rsp.isMessage,true,"success",2000));
            }
            else
            {
                await this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
            }
        }
        catch(error)
        {
            console.log('entramos en el catch',error)
            //@ts-ignore
            await this.changeAlerta(new Alerta(error,true,"danger",2000));
        }
        finally
        {
             await this.changeLoading(new Loading(false,""));
             //await this.changeAlerta(new Alerta("",false,"success"));
        }
        
    }
}

