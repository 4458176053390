import {Save} from '@/Generico/Conexion';
import ResponseGenerico from '../../ResponseGenerico';
import { secondAxios } from '@/plugins/axios';
export class ParteModel
{
     parteId?:number;
     fecha?:string;
     tipoCuadrilla?:number;
     tipoCuadrillaName?:string;
     contratistaId?:number;
     contratistaName?:string;
     usuarioId?:string;
     haciendaId?:number;
     haciendaName?:string;
     ordenInterna?:string;
     aprobado?:boolean;
     rechazado?:boolean;
     cantidadTotal?:number;
     motivo?:string;
     items:Array<ParteDetalleModel>;
     constructor(parteId?:number,fecha?:string,tipoCuadrilla?:number,contratistaId?:number, constratistaName?:string, usuarioId?:string,haciendaId?:number,haciendaName?:string,aprobado?:boolean,rechazado?:boolean,
        motivo?:string,tipoCuadrillaName?:string,_cantidadTotal?:number,
        _ordenInterna?:string)
     {
         this.parteId = parteId;
         this.fecha = fecha;
         this.tipoCuadrilla = tipoCuadrilla ;
         this.contratistaId = contratistaId;
         this.contratistaName = constratistaName;
         this.usuarioId = usuarioId;
         this.haciendaId = haciendaId;
         this.haciendaName = haciendaName;
         this.aprobado = aprobado;
         this.rechazado = rechazado;
         this.motivo = motivo;
         this.cantidadTotal = _cantidadTotal;
         this.items = new Array<ParteDetalleModel>();
         this.tipoCuadrillaName = tipoCuadrillaName;
         this.ordenInterna = _ordenInterna;
     }
     
}

export class ParteDetalleModel
{
    parteId:number=0;
    detalleId:number=0;
    haciendaId?:number=0;
    loteId:number;
    loteName?:string;
    fecha: string;
    fechaCierre:string;
    cuadrillaId:number;
    cuadrillaName:string;
    tipoLaborId:number;
    laborId:number;
    cicloId:number;
    laborName:string;
    precio:number;
    cantidad: number;
    cantidadPlantula:number;
    cantidadExcede:number;
    motivo:string;
    cerrada:boolean;
    imagen:string;
    constructor()
    {
        this.parteId =0;
        this.detalleId =0;
        this.fecha= new Date().toISOString().substr(0, 10);
        this.fechaCierre =new Date().toISOString().substr(0, 10);
        this.cuadrillaId =0;
        this.cuadrillaName ="";
        this.cantidad=0;
        this.cantidadPlantula=0;
        this.tipoLaborId = 0;
        this.cantidadExcede =0;
        this.motivo = "";
        this.loteId=0;
        this.loteName = "";
        this.laborId = 0;
        this.laborName = "";
        this.precio = 0;
        this.cicloId = 0;
        this.cerrada  = false;
        this.imagen = "";

    }
}

export class HoraEmpleado 
{
    detalleId:number =0;
    parteId:number=0
    empleadoId:number;
    empleadoName:string ="";
    laborName:string="";
    laborId:number;
    cantidadHectarea:number;
    cantidadHora:number;
    fecha:string

    constructor(_detalleId:number,_empleadoId:number,_laborId:number,
        _empleadoName:string,_laborName:string,
        _cantidadHectarea:number,_cantidadHora:number,_fecha:string,
        _parteId:number)
    {
        this.empleadoId = _empleadoId;
        this.laborId = _laborId;
        this.cantidadHectarea = _cantidadHectarea;
        this.cantidadHora = _cantidadHora;
        this.fecha = _fecha;
        this.empleadoName = _empleadoName;
        this.laborName = _laborName;
        this.detalleId = _detalleId;
        this.parteId = _parteId;
    }
}

export async function GuardarParte(parte:ParteModel):Promise<ResponseGenerico>
{
    return Save<ParteModel>('parte/guardar',parte,secondAxios);
}


