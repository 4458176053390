

































































import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import Contratista from '@/entidades/Plantacion/Contratista';
import Hacienda from '@/entidades/Plantacion/Hacienda';
import {Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue} from 'vuelidate/lib/validators'
import { TipoCuadrilla } from '@/entidades/Plantacion/Cuadrilla';
import { ParteModel } from '@/entidades/Plantacion/Partes/ParteModel';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import moment from 'moment';
const nameMaestro = "maestroForestal";
const nameForestal = "laboresForestal";
const namespaceAuth = "authModule";
const campoRequerido = "Campo es requerido";
const valorMinimo = "El valor debe ser mayor que 0";
 const tiempo = Date.now();
 const hoy = new Date(tiempo);
@Component({
    components:{
    }
})

export default class extends Vue
{
    @State('usuarioId', {namespace:namespaceAuth} ) usuarioId!: string;
    @State('haciendas', {namespace:nameMaestro} ) haciendas!: Hacienda[];
    @State('tipoCuadrillas', {namespace:nameMaestro} ) tipoCuadrillas!: TipoCuadrilla[];
    @State('contratistas', {namespace:nameMaestro} ) contratistas!: Contratista[];
    @Action('agregarParteLocal', {namespace:nameForestal} ) agregarParteLocal!: Function;
    
    haciendaId:number =0;
    tipoCuadrillaId:number=0;
    contratistaId:number=0;
    parteLocal:ParteModel |undefined;

    @Validations()
     validations = {
         haciendaId : {required, numeric,minValue:minValue(1)},
         tipoCuadrillaId : {required, numeric,minValue:minValue(1)}
}
     

    cambioHacienda()
    {
        var hacienda=this.haciendas.find(e=>e.haciendaId==this.haciendaId);
        if(hacienda != undefined && this.parteLocal != undefined)
        {
             console.log(hacienda);
            this.parteLocal.haciendaId = hacienda.haciendaId;
            this.parteLocal.haciendaName = hacienda.descripcion;
            this.parteLocal.ordenInterna = hacienda.ordenInterna;
        }

    }
    cambiarTipoCuadrilla()
    {
        if(this.parteLocal != undefined && this.tipoCuadrillaId !=0)
        {
            let cuadrilla = this.tipoCuadrillas.find(c=>c.tipoCuadrillaId == this.tipoCuadrillaId);
            this.parteLocal.tipoCuadrilla = this.tipoCuadrillaId;
            this.parteLocal.tipoCuadrillaName = cuadrilla?.descripcion;
        }
    }
    cambioContratista()
    {
        if(this.parteLocal != undefined && this.contratistaId != 0)
        {
            let contratista = this.contratistas.find(c=>c.contratistaId == this.contratistaId);
            this.parteLocal.contratistaId = contratista?.contratistaId;
            this.parteLocal.contratistaName = contratista?.razonSocial;
        }
    }
    async save()
    {  
    try
    {
        
        if(this.parteLocal != undefined)
        {
          this.parteLocal.usuarioId = this.usuarioId;
          this.parteLocal.fecha = moment(new Date()).format("YYYY-MM-DD");
          let rsp:ResponseGenerico = await this.agregarParteLocal(this.parteLocal);
        //  let rsp:ResponseGenerico = new ResponseGenerico(false,"");
          if(rsp.isSuccess == true)
          {
              this.$emit('onOk')
          }
          else
          {
              alert(rsp.isMessage);
          }
          
        } 
    }
    catch(error)
    {
        console.log(error);
    }
           
    }
    //computed
     get mensajeHacienda()
    {
        const errors: string[] = [];
         //@ts-ignore
        if(!this.$v.haciendaId.$dirty) return errors;
         //@ts-ignore
        !this.$v.haciendaId.minValue && errors.push(valorMinimo);
         //@ts-ignore
        !this.$v.haciendaId.required && errors.push(campoRequerido);
        return errors;
    }
     get mensajeTipo()
    {
        const errors: string[] = [];
         //@ts-ignore
        if(!this.$v.tipoCuadrillaId.$dirty) return errors;
         //@ts-ignore
        !this.$v.tipoCuadrillaId.minValue && errors.push(valorMinimo);
         //@ts-ignore
        !this.$v.tipoCuadrillaId.required && errors.push(campoRequerido);
        return errors;
    }
    get mensajeContratista()
    {
         let errors: string[] = [];
        if(this.tipoCuadrillaId ==1)
        {
            return {'validor': false , 'mensaje':errors};
        }
        else
        {
             if(this.contratistaId == 0)
             {
              errors.push('Campo obligatorio');
              return {'validor': true , 'mensaje':errors};
             }
             errors = [];
             return {'validor': false , 'mensaje':errors};
              
        }

    }
    mounted()
    {
        this.parteLocal = new ParteModel();
    }   
}
