




























































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, Mutation, State} from 'vuex-class'
import Contratista from '@/entidades/Plantacion/Contratista';
import {Cuadrilla} from '@/entidades/Plantacion/Cuadrilla';
import { LaborModel } from '@/entidades/Plantacion/Partes/LaborModel';
import TipoLabor from '@/entidades/Plantacion/TipoLabor';
import {Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue} from 'vuelidate/lib/validators'
import { ParteModel,ParteDetalleModel } from '@/entidades/Plantacion/Partes/ParteModel';
import Hacienda from "@/entidades/Plantacion/Hacienda";
import {HaciendaLoteCustomer} from '@/entidades/Plantacion/HaciendaLote';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import moment from 'moment';
const nameMaestro = "maestroForestal";
const nameForestal = "laboresForestal";
const campoRequerido = "Campo es requerido";
const valorMinimo = "El valor debe ser mayor que 0";
@Component({

})
export default class extends Vue
{   
    @Prop({type:Object ,required:true}) parte!:ParteModel;
    @Prop({type:Object}) parteDetalle!:ParteDetalleModel;
    listadoLaborFiltrado:Array<LaborModel> =[];
    listadoLote:Array<HaciendaLoteCustomer> |undefined =[];
    listadoCuadrilla:Array<Cuadrilla> | undefined =[];
    tipoLaborId:number =0;
    cantidadExcede:number =0;
    cantidadPlantula:number =0;
    motivo:string ="";
    menu: boolean= false;
    
    @State('contratistas', {namespace:nameMaestro} ) contratistas!: Contratista[];
    @State('labores', {namespace:nameMaestro} ) labores!: LaborModel[];
    @State('cuadrillas', {namespace:nameMaestro} ) cuadrillas!: Cuadrilla[];
    @State('tipoLabores', {namespace:nameMaestro} ) tipoLabor!: TipoLabor[];
    @State('haciendas', {namespace:nameMaestro} ) haciendas!: Hacienda[];
    @Action('agregarParteDetalle', {namespace:nameForestal} ) agregarParteDetalle!:Function;
    
    cuadrillasFiltradas=new Array<Cuadrilla>();
    excedenteConfirm:boolean=false;

    //declaracion de variables
    detalleParteLocal:ParteDetalleModel = new ParteDetalleModel();
    loteId:number=0;
    cuadrillaId:number=0;
    laborId:number=0;
    cantidad:number=0;
    date:string="";
    fecha:string |null="";
    
    @Validations()
     validations = {
         loteId : {required, numeric,minValue:minValue(1)},
         cuadrillaId : {required, numeric,minValue:minValue(1)},
         laborId : {required, numeric,minValue:minValue(1)},
     }

     closeMenu(fecha:string)
     {
        this.menu = false;
     }
     
    obtenerLote()
     {
         //Obtener los lotes de la hacienda
         this.listadoLote = this.haciendas.find(c=>c.haciendaId == this.parte.haciendaId)?.lotes;
         //filtramos las cuadrillas dependiendo si es contratista o es nomina
         if(this.parte.tipoCuadrilla == 2)
         {
             this.listadoCuadrilla = this.cuadrillas.filter(c=>c.contratistaId == this.parte.contratistaId);
         }
         else
         {
             this.listadoCuadrilla = this.cuadrillas.filter(c=>c.tipoCuadrillaId == 1);
         }

     
     }

    cambiarLote()
    {
      if(this.loteId >0)
      {
          let lote = this.listadoLote?.find(c=>c.loteId == this.loteId);
          this.detalleParteLocal.loteName = lote == undefined ? "" : lote.descripcion;
          this.detalleParteLocal.loteId  = this.loteId;

          this.filtrarLabores();
      }
    }
    cambiarLabor()
    {
       if(this.laborId >0)
       {
           let labor = this.listadoLaborFiltrado.find(c=>c.laborId ==this.laborId);
           this.detalleParteLocal.laborName = labor == undefined ? "" : labor.descripcion;
           this.detalleParteLocal.laborId = this.laborId;
           this.detalleParteLocal.precio = labor == undefined ? 0 : labor.precioUnitario;
           this.detalleParteLocal.cicloId = labor == undefined  ? 0 : labor.cicloId;
       }
    }
    cambiarCuadrilla()
    {
        if(this.cuadrillaId > 0)
        {
            let cuadrilla = this.listadoCuadrilla?.find(c=>c.cuadrillaId == this.cuadrillaId);
            this.detalleParteLocal.cuadrillaName = cuadrilla == undefined ? "" : cuadrilla.descripcion;
            this.detalleParteLocal.cuadrillaId = this.cuadrillaId;
        }
    }

    filtrarLabores()
    {
       if(this.tipoLaborId >0)
       {
           this.listadoLaborFiltrado = this.labores.filter(c=>c.haciendaId == this.parte.haciendaId 
           && c.loteId == this.loteId 
           && c.tipoLaborId == this.tipoLaborId && c.cantidad > 0);
           console.log(this.listadoLaborFiltrado);
       }
    }
    
    async guardar()
    {
        try
        {
            var laborUsar = this.labores.find(c=>c.laborId == this.laborId
            && c.haciendaId == this.parte.haciendaId && c.loteId && this.loteId
            );
            console.log(laborUsar);
            this.detalleParteLocal.parteId = this.parte.parteId == undefined ? 0 :this.parte.parteId;
            this.detalleParteLocal.haciendaId = this.parte == undefined ? 0 : this.parte.haciendaId;
            this.detalleParteLocal.laborId  = this.laborId;
            this.detalleParteLocal.cicloId = this.labores.find(c=>c.laborId == this.laborId)!.cicloId;
            this.detalleParteLocal.cuadrillaId = this.cuadrillaId;
            this.detalleParteLocal.cuadrillaName = this.cuadrillas.find(t=>t.cuadrillaId == this.cuadrillaId)!.descripcion;
            this.detalleParteLocal.tipoLaborId = this.tipoLaborId;
            this.detalleParteLocal.laborName = this.labores.find(c=>c.laborId == this.laborId)!.descripcion;
            let rsp:ResponseGenerico = await this.agregarParteDetalle(this.detalleParteLocal);
            if(rsp.isSuccess == true)
            {
                this.$emit('onCancel');
            }
            else
            {
                alert(rsp.isMessage);
            }
        }
        catch(error)
        {
            console.log(error);
        }
    }    
    
    get getLabor()
    {
        if(this.laborId >0)
        {
            let labor =this.listadoLaborFiltrado.find(c=>c.laborId==this.laborId);
            return {'cantidad': labor?.cantidad, 'precio' : labor?.precioUnitario,'cantidadPlantula' : labor?.cantidadPlantula};
        }
        return 0;
    }

    
    get mensajeLote()
    {
     
        const errors: string[] = [];
        //@ts-ignore
        if(!this.$v.loteId.$dirty) return errors;
        //@ts-ignore
        !this.$v.loteId.minValue && errors.push(valorMinimo);
        //@ts-ignore
        !this.$v.loteId.required && errors.push(campoRequerido);
        return errors;
    }

    get mensajeCuadrilla()
    {

        const errors: string[] = [];
        if(!this.$v.cuadrillaId.$dirty) return errors;
        !this.$v.cuadrillaId.minValue && errors.push(valorMinimo);
        !this.$v.cuadrillaId.required && errors.push(campoRequerido);
        return errors;
    }
    get mensajeLabor()
    {
        const errors: string[] = [];
        if(!this.$v.laborId.$dirty) return errors;
        !this.$v.laborId.minValue && errors.push(valorMinimo);
        !this.$v.laborId.required && errors.push(campoRequerido);
        return errors;
    }
    
    //Ciclo de vida
     async mounted() 
     {
        this.date = moment(new Date()).format("YYYY-MM-DD");
        this.obtenerLote();
    }
}
